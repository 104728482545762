import React, { useRef, useCallback, useEffect, useState, useMemo  } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import Modal from "react-modal";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function SurveyEditor(props) {
  const [survey, setSurvey] = useState();
  const [questions, setQuestions] = useState([]);
  const [templateQuestions, setTemplateQuestions] = useState([]);
  const [selectedTemplateQuestions, setSelectedTemplateQuestions] = useState([]);
  const [templateOptionFields, setTemplateOptionFields] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [templateQuestionDimensions, setTemplateQuestionDimensions] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);
  const [templateQuestionModalOpen, setTemplateQuestionModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lockOptionsEdit, setLockOptionsEdit] = useState(false);
  const [parentDimensionCount, setParentDimensionCount] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [openAccordions, setOpenAccordions] = useState({});
  const [draggingItem, setDraggingItem] = useState(null);
  const toggleAccordion = (dimension) => {
    setOpenAccordions(prevState => ({
      ...prevState,
      [dimension]: !prevState[dimension],
    }));
  };


  const questionKinds = [
    { value: "open", name: "Pergunta aberta" },
    { value: "multiple_choice", name: "Múltipla escolha" },
    {
      value: "multiple_choice_with_last_option_open",
      name: "Múltipla escolha ou outro",
    },
    {
      value: "multiple_choice_allow_many",
      name: "Múltipla escolha com seleção múltipla",
    },
    { value: "date_pick", name: "Selecionar uma data" },
    { value: "slider_10", name: "Slider de 1 a 10" },
    { value: "slider_4", name: "Slider de 1 a 4" },
  ];

  const customModalStyles = {
    content: {
      position: "absolute",
      top: "0%",
      left: "15%",
      bottom: "auto",
      transition: "2s",
      width: "85%",
      height: "100%",
      zIndex: 999,
    },
  };

  const TEMPLATE_QUESTION_KIND_OPTIONS = {
    "multiple_choice": "Multipla Escolha",
    "open": "Aberta",
    "multiple_choice_with_last_option_open": "Multipla Escolha última opção aberta",
    "date_pick": "Data",
    "multiple_choice_allow_many": "Multipla Escolha c/ várias respostas",
    "slider_10": "Slider 10",
    "slider_4": "Slider 4",
  };


  useEffect(() => {
    fetchSurvey();
  }, []);

  useEffect(() => {
    if (!questions.length) return;
    window.addEventListener("keyup", keyPressListenerCallback);
    return () => {
      window.removeEventListener("keyup", keyPressListenerCallback);
    };
  }, [questions]);


  const keyPressListenerCallback = (e) => {
    handleKeyPress(e, questions);
  };

  const handleKeyPress = useCallback((event, questions) => {
    const { key } = event;
    console.log(document.activeElement.tagName);
    if (!["ArrowRight", "ArrowLeft"].includes(key)) return;
    if (document.activeElement.tagName !== "BODY") return;

    let offset = 0;

    if (event.key === "ArrowRight") {
      offset = 1;
    } else if (event.key === "ArrowLeft") {
      offset = -1;
    }

    let nextQuestionOrder;

    if (document.querySelector("#survey-editor").dataset.openQuestionOrder) {
      const currentOpenQuestionOrder = parseInt(
        document.querySelector("#survey-editor").dataset.openQuestionOrder || 0,
        10
      );
      nextQuestionOrder = currentOpenQuestionOrder + offset;
    } else {
      nextQuestionOrder = 0;
    }

    if (nextQuestionOrder <= 0) {
      nextQuestionOrder = 0;
    } else if (nextQuestionOrder >= questions.length) {
      nextQuestionOrder = questions.length - 1;
    }

    const nextQuestion = questions.find((q) => q.order === nextQuestionOrder);

    if (nextQuestion) {
      handleSetOpenQuestion(nextQuestion);
    }
  }, []);

  const addQuestion = () => {
    const newOrder = questions.length;
    const newQuestion = {
      id: newOrder,
      title: "",
      prompt: "",
      alternative_prompt: "",
      scale_description: "",
      respondent_type: "",
      kind: "multiple_choice",
      order: newOrder,
      dimension_ids: [],
      template_question_id: null,
      options: [],
    };

    setQuestions([...questions, newQuestion]);
    handleSetOpenQuestion(newQuestion);
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight + 300,
          behavior: "smooth",
        }),
      100
    );
  };

  const fetchSurvey = async () => {
    const url = `/api/v1/surveys/${props.surveyId}/edit`;
    const response = await axios.get(url);
    console.log(response.data);

    setSurvey(response.data.survey);
    setQuestions(response.data.questions);
    if (response.data.survey.kind === "performance") {
      setTemplateQuestions(response.data.template_questions.filter(e => e.alternative_prompt !== ""));
    } else {
      setTemplateQuestions(response.data.template_questions);
    }
    setTemplateOptionFields(response.data.template_option_fields);
    setDimensions(response.data.dimensions);
    setTemplateQuestionDimensions(response.data.template_question_dimensions);
  };

  const updateSurvey = async () => {
    if (updateLoading) return;
    setErrors([]);

    setUpdateLoading(true);
    const url = `/api/v1/surveys/${props.surveyId}`;
    const restoreQuestions = [...questions].map((q) => {
      const questionObj = Object.assign({}, q);
      questionObj.id = q.original_id;
      return questionObj;
    });
    const response = await axios.patch(url, { questions: restoreQuestions });

    setUpdateLoading(false);

    if (response.data.success) {
      Swal.fire("Sucesso!", "Pesquisa atualizada com sucesso", "success");
      fetchSurvey();
    } else {
      setErrors(response.data.errors);
      Swal.fire("Erro", "Erro ao atualizar pesquisa", "error");
    }
  };

  const handleQuestionChange = (value, field, questionOrder) => {
    const currentQuestions = [...questions];

    const editedQuestion = currentQuestions.find(
      (question) => question.order === questionOrder
    );

    editedQuestion[field] = value;

    setQuestions(currentQuestions);
  };

  const handleQuestionOptionChange = (
    value,
    field,
    questionOrder,
    optionOrder
  ) => {
    const currentQuestions = [...questions];
    const editedQuestion = currentQuestions.find(
      (question) => question.order === questionOrder
    );
    const editedQuestionOption = editedQuestion.options.find(
      (question) => question.order === optionOrder
    );
    editedQuestionOption[field] = value;
    setQuestions(currentQuestions);
  };

  const handleRLDDQuestionsChange = (newList) => {
    let currentQuestions = [...questions];

    currentQuestions.forEach((question) => {
      question.order = newList.findIndex(
        (newListOption) => newListOption.id === question.id
      );
    });
    currentQuestions = currentQuestions.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );

    setQuestions(currentQuestions);
  };

  const handleRLDDSelectedTemplateQuestionsChange = (newList) => {
    let currentQuestions = [...selectedTemplateQuestions];
    currentQuestions.forEach((question) => {
      question.order = newList.findIndex(
        (newListOption) => newListOption.id === question.id
      );
    });
    currentQuestions = currentQuestions.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    if (newList.every(item => selectedTemplateQuestions.includes(item))) {
      setSelectedTemplateQuestions(newList);
    }
  }

  const dimensionOptions = () => {
    return dimensions.map((dimension) => {
      const parentName = dimension.parent_dimension ? dimension.parent_dimension : '';
      const label = parentName ? `${dimension.name} (${parentName})` : dimension.name;
      return { value: dimension.id, label: label };
    });
  };

  const respondentTypeOptions = () => {
    return [
      { value: "all", label: "Todos" },
      { value: "user", label: "Apenas liderado" },
      { value: "leader", label: "Apenas líder" },
    ];
  };

  const populateTemplateOptionFields = (name, questionOrder) => {
    const findTemplateOptionFields = templateOptionFields.find(
      (tof) => tof.name === name
    );
    clearQuestionOptions(questionOrder);
    setLockOptionsEdit(true);

    findTemplateOptionFields.options.forEach((option) => {
      addQuestionOption(questionOrder, option.text, option.value);
    });
  };

  const addQuestionOption = (questionOrder, text = "", value = "") => {
    const currentQuestions = [...questions];

    const editedQuestion = currentQuestions.find(
      (question) => question.order === questionOrder
    );

    editedQuestion.options = [
      ...editedQuestion.options,
      {
        text: text,
        value: value,
        order: editedQuestion.options.length,
        id: editedQuestion.options.length,
      },
    ];

    setQuestions(currentQuestions);
  };

  const clearQuestionOptions = (questionOrder) => {
    const currentQuestions = [...questions];

    const editedQuestion = currentQuestions.find(
      (question) => question.order === questionOrder
    );

    editedQuestion.options = [];

    setLockOptionsEdit(false);
    setQuestions(currentQuestions);
  };

  const openTemplateQuestionModal = () => {
    updateSelectedTemplateQuestions();
    setTemplateQuestionModalOpen(true);
  };

  const selectTemplateQuestion = (questionId) => {
    let currentSelectedTemplateQuestions = [...selectedTemplateQuestions];

    if (!currentSelectedTemplateQuestions.find((tq) => tq.id === questionId)) {
      currentSelectedTemplateQuestions.push(
        templateQuestions.find((tq) => tq.id === questionId)
      );
    } else {
      if (!questions.map((q) => q.template_question_id).includes(questionId)) {
        currentSelectedTemplateQuestions =
          currentSelectedTemplateQuestions.filter(
            (cstq) => cstq.id !== questionId
          );
      }
    }
    setSelectedTemplateQuestions(currentSelectedTemplateQuestions);
    updateParentDimensionCount(currentSelectedTemplateQuestions)
  };



  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const updateSelectedTemplateQuestions = () => {
    setSelectedTemplateQuestions(
      templateQuestions.filter((tq) =>
        questions.map((q) => q.template_question_id).includes(tq.id)
      )
    );
  };


  const buildSelectedTemplateQuestions = () => {
    if (!validateSelectedTemplateQuestions()) {
      alert("Você precisa selecionar entre o mínimo e o máximo para cada dimensão.");
      return;
    }

    const newTemplateQuestions = selectedTemplateQuestions.filter(
      (stq) => !questions.map((q) => q.template_question_id).includes(stq.id)
    );

    const currentQuestions = [...questions];

    newTemplateQuestions.forEach((question) => {
      const currentOrder = currentQuestions.length;
      currentQuestions.push({
        id: currentQuestions.length,
        title: question.title,
        prompt: question.prompt,
        kind: question.kind,
        optional: question.optional,
        respondent_type: question.respondent_type,
        order: currentOrder,
        alternative_prompt: question.alternative_prompt,
        dimension_ids: question.dimension_ids,
        template_question_id: question.id,
        options: question.options.map((option) => {
          return {
            id: option.order,
            text: option.text,
            value: option.value,
            order: option.order,
          };
        }),
      });
    });

    setQuestions(currentQuestions);
    setTemplateQuestionModalOpen(false);
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight + 300,
          behavior: "smooth",
        }),
      100
    );
  };

  const mapDimensionIdToName = (dimensionId) => {
    const dimension = templateQuestionDimensions.find(d => d.id === parseInt(dimensionId));
    if (dimension) {
      const parentName = dimension.parent_dimension ? dimension.parent_dimension.trim().toLowerCase() : '';
      const dimensionName = dimension.name ? dimension.name.trim().toLowerCase() : '';
      return parentName ? `${parentName} - ${dimensionName}` : ` - ${dimensionName}`;
    }

    return null;
  };

  const validateSelectedTemplateQuestions = () => {
    const dimensionQuestions = {};
    selectedTemplateQuestions.forEach((question) => {
      question.dimension_ids.forEach((dimension_id) => {
        if (!dimensionQuestions[dimension_id]) {
          dimensionQuestions[dimension_id] = [];
        }
        dimensionQuestions[dimension_id].push(question);
      });
    });

    let allDimensionsValid = true;
    const maxUnselectedDimensions = 3;
    console.log(dimensionQuestions);
    console.log(groupedByDimension);
    for (const [dimension_id, questions] of Object.entries(dimensionQuestions)) {
      const dimensionName = mapDimensionIdToName(dimension_id);
      if (!dimensionName) continue;
      const totalQuestions = groupedByDimension[dimensionName]?.length || 0;
      const minSelection = totalQuestions <= 3 ? totalQuestions : 3;
      const maxSelection = Math.min(totalQuestions, 15);
      console.log(
        `${dimensionName}====> questions: ${totalQuestions}  | selected: ${questions.length} ----  min: ${minSelection} -------- max: ${maxSelection}`
      );
      if (questions.length < minSelection || questions.length > maxSelection) {
        allDimensionsValid = false;
        break;
      }
    }
    if (!allDimensionsValid) {
      return false;
    }
    const allDimensions = Object.keys(groupedByDimension);
    const selectedDimensions = Object.keys(dimensionQuestions).map(mapDimensionIdToName);
    const unselectedDimensionsCount = allDimensions.filter(dim => !selectedDimensions.includes(dim)).length;

    if (unselectedDimensionsCount > maxUnselectedDimensions) {
      return false;
    }
    return true;
  };



  const deleteQuestion = (e, questionOrder) => {
    e.stopPropagation();

    const filteredOrderedQuestions = questions
      .filter((q) => {
        return q.order !== questionOrder;
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    const filteredOrderedErrors = errors
      .filter((e) => {
        return e.order !== questionOrder;
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    filteredOrderedQuestions.forEach((question) => {
      question.order = filteredOrderedQuestions.indexOf(question);
    });
    filteredOrderedErrors.forEach((question) => {
      question.order = filteredOrderedQuestions.indexOf(question);
    });

    setQuestions(filteredOrderedQuestions);
    handleSetOpenQuestion(null);
    setErrors(filteredOrderedErrors);
  };



  const handleSetOpenQuestion = (question) => {
    document.querySelector("#survey-editor").dataset.openQuestionOrder =
      question.order;
    setOpenQuestion(question);
  };

  let modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{
        'color': ['black', 'red', 'blue', 'white', 'green', 'yellow', 'pink', 'gray', '#90a7bf', 'color-picker', '#627393', '#576081']
      }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }


  const groupedByDimension = templateQuestions.reduce((acc, question) => {
    const dimension = templateQuestionDimensions?.find(e => e.id === question.dimension_ids[0]);
    if (!dimension) {
      return acc;
    }

    const parentName = dimension.parent_dimension ? dimension.parent_dimension.trim().toLowerCase() : '';
    const dimensionNameNormalized = dimension.name ? dimension.name.trim().toLowerCase() : '';
    const fullDimensionName = `${parentName} - ${dimensionNameNormalized}`;

    if (!acc.hasOwnProperty(fullDimensionName)) {
      acc[fullDimensionName] = [];
    }
    acc[fullDimensionName].push(question);

    return acc;
  }, {});



  const updateParentDimensionCount = (questions) => {
    const updatedParentDimensionCount = { ...parentDimensionCount };
    const OTHER_DIMENSIONS_KEY = "Outras Dimensões";
    console.log('Questions being processed:', questions); // Debug log
    const resetParentDimensionCount = () => {
      Object.keys(updatedParentDimensionCount).forEach((parentDimension) => {
        updatedParentDimensionCount[parentDimension].count = 0;
        Object.keys(updatedParentDimensionCount[parentDimension].dimensions).forEach((dimensionName) => {
          updatedParentDimensionCount[parentDimension].dimensions[dimensionName] = 0;
        });
      });
    };
    resetParentDimensionCount();
    questions.forEach((question) => {
      if (question.dimension_ids && question.dimension_ids.length > 0) {
        question.dimension_ids.forEach((dimensionId) => {
          const dimension = templateQuestionDimensions?.find(
            (dim) => parseInt(dim.id) === parseInt(dimensionId)
          );

          if (dimension) {
            const parentDimension = dimension.parent_dimension;

            if (parentDimension) {
              if (!updatedParentDimensionCount[parentDimension]) {
                updatedParentDimensionCount[parentDimension] = { count: 0, dimensions: {} };
              }
              updatedParentDimensionCount[parentDimension].count++;
              if (!updatedParentDimensionCount[parentDimension].dimensions[dimension.name]) {
                updatedParentDimensionCount[parentDimension].dimensions[dimension.name] = 0;
              }
              updatedParentDimensionCount[parentDimension].dimensions[dimension.name]++;
            } else {
              if (!updatedParentDimensionCount[OTHER_DIMENSIONS_KEY]) {
                updatedParentDimensionCount[OTHER_DIMENSIONS_KEY] = { count: 0, dimensions: {} };
              }
              updatedParentDimensionCount[OTHER_DIMENSIONS_KEY].count++;
              if (!updatedParentDimensionCount[OTHER_DIMENSIONS_KEY].dimensions[dimension.name]) {
                updatedParentDimensionCount[OTHER_DIMENSIONS_KEY].dimensions[dimension.name] = 0;
              }
              updatedParentDimensionCount[OTHER_DIMENSIONS_KEY].dimensions[dimension.name]++;
            }
          }
        });
      }
    });

    console.log('Updated Parent Dimension Count:', updatedParentDimensionCount);
    setParentDimensionCount(updatedParentDimensionCount);
  };


  const toggleSelectAllTemplateQuestionsForDimension = (dimension) => {
    const dimensionQuestions = groupedByDimension[dimension];
    const allSelected = dimensionQuestions.every((q) =>
      selectedTemplateQuestions.includes(q)
    );

    if (allSelected) {
      setSelectedTemplateQuestions((prev) =>
        prev.filter((q) => !dimensionQuestions.includes(q))
      );
    } else {
      setSelectedTemplateQuestions((prev) =>
        Array.from(new Set([...prev, ...dimensionQuestions]))
      );
    }
  };


  const closeModal = () => {
    setTemplateQuestionModalOpen(false);
    setParentDimensionCount({}); // Reset parentDimensionCount
  };

  return (
    <div id="survey-editor" className="flex w-100 gap-32">
      <div className="w-100 f-40">
        <div className="flex gap-8">
          <p className="btn btn-primary w-100" onClick={addQuestion}>
            <i className="fa fa-plus"></i>
            <br />
            Adicionar pergunta
          </p>
          <p
            className="btn btn-primary w-100"
            onClick={openTemplateQuestionModal}
          >
            <i className="fa fa-plus"></i>
            <br />
            Pergunta de Template
          </p>
          <p className="btn btn-success w-100" onClick={() => updateSurvey()}>
            <i className="fa fa-save"></i>
            <br />
            Atualizar formulário
          </p>
        </div>

        <div className="">
          <RLDD
            items={questions}
            itemRenderer={(question) => {
              return (
                <div
                  className={`question-header p-2 mb-2 lower-separator br-8 bx-1 clickable flex between align-items-center ${
                    openQuestion && question.order === openQuestion.order
                      ? "bg-primary text-white"
                      : "bg-gs-250"
                  }`}
                  onClick={() => handleSetOpenQuestion(question)}
                  key={question.order}
                >
                  <div className="flex gap-24 align-items-center">
                    <p className="fs-16 m-0">
                      {errors.find((e) => e.order === question.order) && (
                        <i className="danger-text fa fa-times"></i>
                      )}
                      {openQuestion && openQuestion.order === question.order ? (
                        <i className="fa fa-caret-down px-2"></i>
                      ) : (
                        <i className="fa fa-caret-up px-2"></i>
                      )}
                      <span className="px-3 fw-700">{question.order + 1}</span>

                      {question.template_question_id && (
                        <span className="fs-10 mr-2 rounded p-1 bg-dark text-white">
                          T
                        </span>
                      )}
                      {(() => {
                        const dimensionId = question.dimension_ids[0];
                        const dimensionInDimensions = dimensions.find((d) => d.id === dimensionId);
                        const dimensionInTemplateQuestionDimensions = templateQuestionDimensions.find(
                          (d) => d.id === dimensionId
                        );

                        const dimensionName = dimensionInDimensions
                          ? dimensionInDimensions.name
                          : dimensionInTemplateQuestionDimensions
                          ? dimensionInTemplateQuestionDimensions.name
                          : null;

                        if (dimensionName) {
                          return (
                            <span className="fs-10 mr-2 rounded p-1 bg-gs-150 text-white">
                              {dimensionName}
                            </span>
                          );
                        }
                        return null;
                      })()}

                      {question.title}
                    </p>
                  </div>
                </div>
              );
            }}
            onChange={(v) => handleRLDDQuestionsChange(v)}
          />
        </div>
      </div>

      <div className="w-100 questions-container">
        {openQuestion && (
          <div key={openQuestion.order} className="bg-gs-250 br-8 mb-2">
            <div className="question-content p-2 position-sticky me-3" >
              <p className="fs-16">
                <span className="fs-24 pr-1 fw-700">
                  Pergunta #{openQuestion.order + 1}
                </span>
                {openQuestion.title}
              </p>
              {openQuestion.template_question_id && (
                <p className="info-text p-4 br-8">
                  <i className="fa fa-info-circle px-3 fs-20"></i>Essa pergunta
                  não pode ser editada pois é uma pergunta de template
                </p>
              )}
              {errors.find((e) => e.order === openQuestion.order) && (
                <p className="danger-text">
                  {errors.find((e) => e.order === openQuestion.order).error}
                </p>
              )}
              <input
                value={openQuestion.title}
                onChange={(e) =>
                  handleQuestionChange(
                    e.target.value,
                    "title",
                    openQuestion.order
                  )
                }
                placeholder="Título da pergunta"
                readOnly={openQuestion.template_question_id}
                className="form-control mb-2"
              ></input>
              <ReactQuill
                theme="snow"
                modules={modules}
                value={openQuestion.prompt || ''}
                placeholder="Conteúdo da pergunta"
                readOnly={!!openQuestion.template_question_id}
                className="my-3"
                onChange={(e) =>
                  handleQuestionChange(e, "prompt", openQuestion.order)
                }
              />
              {survey && survey.kind === "performance" && (
                <div>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={openQuestion.alternative_prompt || ''}
                    readOnly={!!openQuestion.template_question_id}
                    placeholder="Conteúdo da pergunta em terceira pessoa"
                    className="my-3"
                    onChange={(e) =>
                      handleQuestionChange(
                        e,
                        "alternative_prompt",
                        openQuestion.order
                      )
                    }
                  />
                  <Select
                    options={respondentTypeOptions()}
                    placeholder="Tipo de respondente"
                    isDisabled={!!openQuestion.template_question_id}
                    onChange={(e) =>
                      handleQuestionChange(
                        e.value,
                        "respondent_type",
                        openQuestion.order
                      )
                    }
                    value={respondentTypeOptions().find(
                      (opt) => openQuestion.respondent_type === opt.value
                    )}
                    className="my-3"
                  />
                </div>
              )}
              <ReactQuill
                theme="snow"
                modules={modules}
                value={openQuestion.scale_description || ''}
                placeholder="Legenda da escala de avaliação"
                readOnly={!!openQuestion.template_question_id}
                className="my-3"
                onChange={(e) =>
                  handleQuestionChange(
                    e,
                    "scale_description",
                    openQuestion.order
                  )
                }
              />
              <Select
                options={dimensionOptions()}
                isMulti
                placeholder="Dimensões"
                isDisabled={!!openQuestion.template_question_id}
                onChange={(e) =>
                  handleQuestionChange(
                    e.map((selectedOption) => selectedOption.value),
                    "dimension_ids",
                    openQuestion.order
                  )
                }
                value={dimensionOptions().filter((opt) =>
                  openQuestion.dimension_ids.includes(opt.value)
                )}
                className="my-3"
              />
              <div className="flex align-items-center gap-12 my-3">
                <input
                  type="checkbox"
                  value={openQuestion.optional}
                  checked={openQuestion.optional}
                  name="template_question[optional]"
                  onChange={(e) =>
                    handleQuestionChange(
                      openQuestion.template_question_id
                        ? openQuestion.optional
                        : !openQuestion.optional,
                      "optional",
                      openQuestion.order
                    )
                  }
                />
                <span className="px-3">Pergunta opcional</span>
              </div>
              <select
                value={openQuestion.kind}
                onChange={(e) =>
                  handleQuestionChange(
                    e.target.value,
                    "kind",
                    openQuestion.order
                  )
                }
                disabled={!!openQuestion.template_question_id}
                placeholder="Tipo de pergunta"
                className="form-control mb-2"
              >
                {questionKinds.map((kind) => {
                  return (
                    <option value={kind.value} key={kind.value}>
                      {kind.name}
                    </option>
                  );
                })}
              </select>
              {openQuestion.kind.includes("multiple_choice") && (
                <div>
                  <select
                    value={openQuestion.kind}
                    onChange={(e) =>
                      populateTemplateOptionFields(
                        e.target.value,
                        openQuestion.order
                      )
                    }
                    disabled={!!openQuestion.template_question_id}
                    placeholder="Popular opções de template"
                    className="form-control mb-2"
                  >
                    <option default>Opções de template</option>;
                    {templateOptionFields.map((optionField) => {
                      return (
                        <option value={optionField.name} key={optionField.name}>
                          {optionField.name}
                        </option>
                      );
                    })}
                  </select>
                  {openQuestion.options.length ? (
                    <div>
                      <div className="mb-3">
                        {openQuestion.options.map((option) => {
                          return (
                            <div
                              className="px-3 py-1 bg-gs-210 mb-1 flex align-items-center gap-24"
                              key={option.id}
                            >
                              <p className="m-0 mr-3">{option.order}</p>
                              <input
                                value={option.text}
                                onChange={(e) =>
                                  handleQuestionOptionChange(
                                    e.target.value,
                                    "text",
                                    openQuestion.order,
                                    option.order
                                  )
                                }
                                readOnly={
                                  openQuestion.template_question_id ||
                                  lockOptionsEdit
                                }
                                placeholder="Texto da opção"
                                className="form-control m-0"
                              ></input>
                              <input
                                value={option.value}
                                type="number"
                                onChange={(e) =>
                                  handleQuestionOptionChange(
                                    e.target.value,
                                    "value",
                                    openQuestion.order,
                                    option.order
                                  )
                                }
                                readOnly={
                                  openQuestion.template_question_id ||
                                  lockOptionsEdit
                                }
                                placeholder="Valor da opção"
                                className="form-control m-0 f-20"
                              ></input>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <p>Nenhuma opção até o momento</p>
                  )}
                  <div className="flex gap-24">
                    {!openQuestion.template_question_id && !lockOptionsEdit && (
                      <p
                        className="btn btn-primary"
                        onClick={() => addQuestionOption(openQuestion.order)}
                      >
                        <i className="fa fa-plus pr-3"></i>Adicionar opção de
                        resposta
                      </p>
                    )}
                    {!openQuestion.template_question_id && (
                      <p
                        className="btn btn-primary"
                        onClick={() => clearQuestionOptions(openQuestion.order)}
                      >
                        <i className="fa fa-trash pr-3"></i>Limpar opções de
                        resposta
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div>
                <p
                  className="btn btn-danger m-0 w-100"
                  onClick={(e) => deleteQuestion(e, openQuestion.order)}
                >
                  <i className="fa fa-trash px-3"></i>
                  <span className="m-0">Apagar pergunta</span>
                </p>
              </div>
              <p className="fs-12 mt-3">#{openQuestion.original_id}</p>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={templateQuestionModalOpen}
        // onRequestClose={() => setTemplateQuestionModalOpen(false)}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Filter Modal"
      >
        <div className="flex gap-8 justify-content-between">
          <h2 className="text-center poppins fs-24 gs-50 mb-4">
            Adicionar perguntas de Template
          </h2>
          <p
            className="btn btn-danger"
            // onClick={() => setTemplateQuestionModalOpen(false)}
            onClick={closeModal}

          >
            Fechar
          </p>
        </div>

        <p
          className="btn btn-success w-100"
          onClick={() => buildSelectedTemplateQuestions()}
        >
          Adicionar perguntas
        </p>

        <div className="d-flex justify-content-between align-items-stretch my-3" style={{gap: "10px"}}>
          {Object.keys(parentDimensionCount).map((parentDimension) => (<>
            <div key={parentDimension} className="w-50 p-2 border rounded" style={{boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"}}>
              <h4 className="text-center fs-18 mb-4">
                {parentDimension}: ({Object.keys(parentDimensionCount[parentDimension].dimensions).length} Dimensões | {parentDimensionCount[parentDimension].count} Perguntas)
              </h4>
              <div className="p-2 d-flex justify-content-between align-items-center flex-wrap" style={{gap: '5px'}}>
                {Object.entries(parentDimensionCount[parentDimension].dimensions).map(
                  ([dimensionName, count]) => (
                    <span className="fs-12 mr-2 rounded p-1 "
                      style={{
                        background:  "rgb(255, 255, 255)",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
                      }}
                    >
                      {dimensionName}: {count}
                    </span>
                  )
                )}
              </div>
            </div>
          </>))}
        </div>

        <div className="d-flex justify-content-between"
          style={{gap: '15px',}}
        >
          <div className="w-50">
            {Object.entries(groupedByDimension)
            .sort(([dimensionA], [dimensionB]) => {
              const normalizedA = dimensionA.replace(/^ - /, '').trim();
              const normalizedB = dimensionB.replace(/^ - /, '').trim();

              const [parentA, displayA] = normalizedA.split(' - ').map(part => part.trim().toLowerCase());
              const [parentB, displayB] = normalizedB.split(' - ').map(part => part.trim().toLowerCase());

              if (parentA === 'dados básicos' && parentB !== 'dados básicos') return -1;
              if (parentA !== 'dados básicos' && parentB === 'dados básicos') return 1;

              if (parentA.startsWith('clima') && !parentB.startsWith('clima')) return -1;
              if (!parentA.startsWith('clima') && parentB.startsWith('clima')) return 1;
              if (parentA.startsWith('clima') && parentB.startsWith('clima')) {
                const isAbertasA = /aberta(s)?/.test(displayA);
                const isAbertasB = /aberta(s)?/.test(displayB);
                if (isAbertasA && !isAbertasB) return 1;
                if (!isAbertasA && isAbertasB) return -1;
                return displayA.localeCompare(displayB);
              }

              if (parentA.startsWith('felicidade') && !parentB.startsWith('felicidade')) return -1;
              if (!parentA.startsWith('felicidade') && parentB.startsWith('felicidade')) return 1;
              if (parentA.startsWith('felicidade') && parentB.startsWith('felicidade')) {
                const isAbertasA = /aberta(s)?/.test(displayA);
                const isAbertasB = /aberta(s)?/.test(displayB);
                if (isAbertasA && !isAbertasB) return 1;
                if (!isAbertasA && isAbertasB) return -1;
                return displayA.localeCompare(displayB);
              }

              if (parentA === '' && parentB !== '') return 1;
              if (parentA !== '' && parentB === '') return -1;

              return normalizedA.localeCompare(normalizedB);
            })
            .map(([dimension, questions]) => {
              const [parentName, displayDimensionName] = dimension.split(' - ').map(word =>
                word.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
              );

              const selectedCount = questions.filter(q => selectedTemplateQuestions.includes(q)).length;
              const minSelection = questions.length <= 3 ? questions.length : 3;
              const maxSelection = Math.min(questions.length, 15);

              return (
                <div key={dimension} className={`rounded border ${openAccordions[dimension] ?  "p-3 my-4" : "p-2 my-4"}`}
                  style={{
                    background: openAccordions[dimension]
                      ? "rgb(255, 255, 255)" // Open
                      : questions.some((q) => selectedTemplateQuestions.includes(q))
                      ? "rgb(240, 240, 240)" // Closed with questions
                      : "rgb(255, 255, 255)", // Default closed

                      boxShadow: openAccordions[dimension]
                      ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px" // Open
                      : "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px", // Default closed
                  }}
                >
                  <div className={`d-flex justify-content-between cursor-pointer ${openAccordions[dimension] ?  "mb-3" : "mb-0"}`} onClick={() => toggleAccordion(dimension)}>
                    <h4 className={`cursor-pointer mb-0 fw-6`}>{`${parentName} - ${displayDimensionName}`}</h4>
                    <h4 className={`cursor-pointer mb-0 fw-6`}>
                      {selectedCount} Escolhidas | Min: {minSelection} | Max: {maxSelection}
                    </h4>
                    <div className="d-flex align-items-center justify-content-end">
                      <input
                        type="checkbox"
                        className="mr-2"
                        id={`select-all-${dimension}`}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => toggleSelectAllTemplateQuestionsForDimension(dimension)}
                        checked={questions.every((q) => selectedTemplateQuestions.includes(q))}
                      />
                      <label
                        htmlFor={`select-all-${dimension}`}
                        className={`cursor-pointer mb-0 text-black`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {questions.every((q) => selectedTemplateQuestions.includes(q)) ? "Retirar todas" : "Escolher todas"}
                      </label>
                    </div>
                  </div>
                  {openAccordions[dimension] && (
                    <div className="accordion-content">
                      {questions.map((templateQuestion, index) => (
                        <div
                          key={templateQuestion.id}
                          className={`br-8 px-3 py-2 mb-2 bx-1 ${selectedTemplateQuestions.includes(templateQuestion) ? "bg-primary text-white" : ""}`}
                          onClick={() => selectTemplateQuestion(templateQuestion.id)}
                          style={{background: "rgb(253, 253, 253)"}}
                        >
                          <p className="m-0 clickable">
                            {index + 1}.{" "}
                            {selectedTemplateQuestions.includes(templateQuestion) && (
                              <i className="fa fa-check pr-3 text-white"></i>
                            )}
                            {templateQuestion.title} ({templateQuestion.prompt})
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-50 p-3 my-3 rounded position-relative" style={{height: 'fit-content', boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"}}>
            <div className="d-flex justify-content-between mb-2">
              <h3 className="">{`Total: ${selectedTemplateQuestions.length}`}</h3>
            </div>
            <RLDD
              items={selectedTemplateQuestions}
              itemRenderer={(question) => {
                return (
                  <div
                    className={`border rounded p-2 h-auto mb-2 lower-separator br-8 bx-1 clickable flex between align-items-center`}
                    key={question.id}
                    style={{background: "rgb(253, 253, 253)"}}
                  >
                    <div className="flex gap-24 align-items-center">
                      <p className="fs-16 m-0">
                        <span className="px-3 fw-700">{selectedTemplateQuestions.indexOf(question) + 1}</span>
                        {(() => {
                          const dimensionId = question.dimension_ids[0];
                          const dimensionInDimensions = dimensions.find((d) => d.id === dimensionId);
                          const dimensionInTemplateQuestionDimensions = templateQuestionDimensions.find(
                            (d) => d.id === dimensionId
                          );

                          const dimensionName = dimensionInDimensions
                            ? dimensionInDimensions.name
                            : dimensionInTemplateQuestionDimensions
                            ? dimensionInTemplateQuestionDimensions.name
                            : null;

                          const parentDimensionName = dimensionInDimensions
                            ? dimensionInDimensions.parent_dimension
                            : dimensionInTemplateQuestionDimensions
                            ? dimensionInTemplateQuestionDimensions.parent_dimension
                            : null;

                          if (dimensionName) {
                            return (
                              <span className="fs-10 mr-2 rounded p-1 bg-gs-150 text-white">
                                {dimensionName}{parentDimensionName ? ` | ${parentDimensionName}` : ``}
                              </span>
                            );
                          }
                          return null;
                        })()}
                        {question.title}
                        <span className="fs-10 px-2">
                          {TEMPLATE_QUESTION_KIND_OPTIONS[question.kind]}
                        </span>
                        {question.optional && (
                          <span className="fs-10 px-2">
                            Opcional
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                );
              }}
              onChange={(newList) => handleRLDDSelectedTemplateQuestionsChange(newList)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
