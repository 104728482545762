import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export function SurveyAnswer(props) {
  const [survey, setSurvey] = useState([]);
  const [surveyCycleQuestions, setSurveyCycleQuestions] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [lastScreen, setLastScreen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [waitingSave, setWaitingSave] = useState(false);
  const [blockUpdateSurvey, setBlockUpdateSurvey] = useState(false);

  const topOfPageElement = useRef(null);

  useEffect(() => {
    fetchSurvey();

    return () => {
      debouncedUpdateSurvey(true);
    };
  }, []);

  function mobilePage() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const letterOrders = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T"
  ];

  const currentQuestion = () => {
    return surveyCycleQuestions.find(
      (question) => question.id === currentQuestionId
    );
  };

  const sliderMarks = (max) => {
    const arrayFromOneToMax = Array.from(Array(max).keys());
    const baseLabels = {};
    arrayFromOneToMax.forEach((number) => {
      baseLabels[number + 1] = { label: (number + 1).toString() };
    });
    if (
      currentQuestion() &&
      currentQuestion().answer &&
      currentQuestion().answer.value
    ) {
      baseLabels[parseInt(currentQuestion().answer.value, 10)].style = {
        color: props.theme.colors.primary,
        fontSize: "20px",
        fontWeight: "700",
      };
    }
    return baseLabels;
  };

  const sliderColorForValue = (value, maxValue) => {
    const penalty = maxValue > 5 ? 2 : 1;

    var hue = (((value - penalty) / maxValue) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  };

  const fetchSurvey = async () => {
    const url = `/api/v1/user_survey_cycles/${props.id}`;
    const response = await axios.get(url);

    setSurvey(response.data.survey);
    setSurveyCycleQuestions(response.data.survey_cycle_questions);
    setCurrentQuestionId(response.data.survey.current_question_id);
    if (!response.data.survey.current_question_id) {
      setLastScreen(true);
    } else {
      setLastScreen(false);
    }
  };

  const clearAnswerHasChanged = () => {
    const currentQuestions = [...surveyCycleQuestions];

    currentQuestions.forEach((question) => {
      question.answer_has_changed = false;
    });

    setSurveyCycleQuestions(currentQuestions);
  };

  const updateSurvey = async (saveAll = false) => {
    if (blockUpdateSurvey) return;

    const nothingChanged = [...surveyCycleQuestions].every(
      (scq) => !scq.answer_has_changed
    );
    if (nothingChanged && !saveAll) return;

    if (saveAll) setBlockUpdateSurvey(true);

    setSaving(true);
    setWaitingSave(false);

    const url = `/api/v1/user_survey_cycles/${props.id}`;
    await axios.patch(url, {
      survey_cycle_questions: surveyCycleQuestions,
      save_all: saveAll,
    });
    clearAnswerHasChanged();
    setSaving(false);
    setSaved(true);
  };

  const saveAndExit = async () => {
    await updateSurvey();
    const swalResult = await Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success w-100 px-5",
      },
      buttonsStyling: false,
    }).fire({
      text: "Sua pesquisa foi salva e você pode continuá-la depois. Mas lembre-se: sua pesquisa só será concluida quando for totalmente finalizada!",
      icon: "success",
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: "OK",
    });

    if (swalResult.isConfirmed) {
      window.location = props.returnUrl;
    }
  };

  const finishSurvey = async () => {
    const swalResult = await Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success w-40",
        cancelButton: "btn btn-danger mx-3 w-40",
      },
      buttonsStyling: false,
    }).fire({
      text: "Tem certeza que deseja finalizar a pesquisa? Você não poderá alterar suas respostas.",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    });

    if (swalResult.isConfirmed) {
      await updateSurvey();

      const url = `/api/v1/user_survey_cycles/${props.id}/finish`;
      const response = await axios.patch(url);

      if (response.data.success) {
        window.location = props.returnUrl;
      } else {
        Swal.fire("Erro", response.data.error, "error");
        setBlockUpdateSurvey(false);
        fetchSurvey();
      }
    }
  };

  const handleAnswerChange = async (
    questionId,
    text,
    value,
    survey_cycle_question_option_id,
    other_value = ""
  ) => {
    const currentQuestions = [...surveyCycleQuestions];

    const editedQuestion = currentQuestions.find(
      (question) => question.id === questionId
    );

    editedQuestion.answer.text = text;
    editedQuestion.answer.value = value;
    editedQuestion.answer.other_value = other_value;
    editedQuestion.answer.survey_cycle_question_option_id =
      survey_cycle_question_option_id;
    editedQuestion.answer_has_changed = true;

    setSurveyCycleQuestions(currentQuestions);
    setSaved(false);
    setWaitingSave(true);
  };

  const handleMultipleAnswerAllowManyOptionSelect = (
    questionId,
    survey_cycle_question_option_id
  ) => {
    const currentQuestions = [...surveyCycleQuestions];

    const editedQuestion = currentQuestions.find(
      (question) => question.id === questionId
    );

    const currentSelectedIds = [
      ...(editedQuestion.answer.survey_cycle_question_option_ids || []),
    ];

    const elementIndex = currentSelectedIds.indexOf(
      survey_cycle_question_option_id
    );
    if (elementIndex === -1) {
      currentSelectedIds.push(survey_cycle_question_option_id);
    } else {
      currentSelectedIds.splice(elementIndex, 1);
    }

    editedQuestion.answer.survey_cycle_question_option_ids = currentSelectedIds;
    editedQuestion.answer.text = currentSelectedIds
      .map(
        (selectedId) =>
          editedQuestion.options.find((eqo) => eqo.id === selectedId).text
      )
      .join(", ");
    editedQuestion.answer.value = 0;
    editedQuestion.answer.survey_cycle_question_option_id = null;
    editedQuestion.answer_has_changed = true;

    setSurveyCycleQuestions(currentQuestions);
  };

  const debouncedRun = (fn, delay) => {
    const timeoutRef = useRef(null);

    function debouncedFn(...args) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        fn(...args);
      }, delay);
    }

    return debouncedFn;
  };
  const debouncedUpdateSurvey = debouncedRun(updateSurvey, 3000);

  const goToNext = () => {
    if (!canGoToNext()) return;

    if (lastScreen) {
      finishSurvey();
      return;
    }

    const currentQuestion = surveyCycleQuestions.find(
      (q) => q.id === currentQuestionId
      );
    const nextQuestion = surveyCycleQuestions.sort((a, b) => (a.order > b.order ? 1 : -1)).find(
      (q) => q.order > currentQuestion.order
    );

    if (nextQuestion) {
      setCurrentQuestionId(nextQuestion.id);
    } else {
      setCurrentQuestionId(null);
      setLastScreen(true);
    }

    debouncedUpdateSurvey();

    topOfPageElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const goToNextUnanswered = () => {
    if (!canGoToNext()) return;

    if (lastScreen) {
      finishSurvey();
      return;
    }

    const currentQuestion = surveyCycleQuestions.find(
      (q) => q.id === currentQuestionId
    );
    const nextUnansweredQuestion = [...surveyCycleQuestions]
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .find(
        (q) =>
          q.order > currentQuestion.order &&
          q.answer.value == null &&
          !q.answer_has_changed
      );

    if (nextUnansweredQuestion) {
      setCurrentQuestionId(nextUnansweredQuestion.id);
    } else {
      setCurrentQuestionId(null);
      setLastScreen(true);
    }

    debouncedUpdateSurvey();
  };

  const goToPrevious = () => {
    const currentQuestion = surveyCycleQuestions.find(
      (q) => q.id === currentQuestionId
    );

    if (currentQuestion && currentQuestion.order === 0) return;
    setLastScreen(false);

    if (!currentQuestion) {
      setCurrentQuestionId(
        [...surveyCycleQuestions].sort((a, b) =>
          a.order > b.order ? -1 : 1
        )[0].id
      );
      return;
    }

    const previousQuestion = surveyCycleQuestions.sort((a, b) => (a.order < b.order ? 1 : -1)).find(
      (q) => q.order < currentQuestion.order
    );

    if (previousQuestion) {
      setCurrentQuestionId(previousQuestion.id);
    } else {
      setCurrentQuestionId(
        [...surveyCycleQuestions].sort((a, b) =>
          a.order > b.order ? -1 : 1
        )[0].id
      );
    }

    topOfPageElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    debouncedUpdateSurvey();
  };

  const ensureSsl = (url) => {
    if (url && url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }
    return url;
  }

  const canGoToNext = () => {
    if (lastScreen) return true;

    const currentQuestion = surveyCycleQuestions.find(
      (q) => q.id === currentQuestionId
    );
    if (!currentQuestion) return false;

    if (currentQuestion.is_immediate_leader && !survey.immediate_leader)
      return true;

    return currentQuestion.optional || currentQuestion.answer.text;
  };

  const lastOption = (question) => {
    return question.options[question.options.length - 1];
  };

  return (
    <div className="vw-70 flex align-items-center justify-content-around mx-auto">
      {survey && (
        <div className="flex column w-100">
          {lastScreen ? (
            <>
              <p>Você respondeu a todos as perguntas.</p>
              <p>
                <b>Para concluir e enviar a pesquisa, aperte em Finalizar.</b>
              </p>
              <div className="flex align-items-center gap-24 w-100 mt-3">
                <p className="btn btn-primary w-40" onClick={goToPrevious}>
                  Voltar
                </p>
                <p className="btn btn-primary w-60" onClick={finishSurvey}>
                  Finalizar
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                className="flex column align-items-center w-100"
                ref={topOfPageElement}
              >
                {surveyCycleQuestions.map((question, index) => {
                  return (
                    <>
                      {question.id === currentQuestionId && (
                        <div className="w-100" key={question.id}>
                          <p>
                            {question.dimensions.length > 0 && (
                              <p className="bg-primary p-2 br-8 text-white">
                                <i className="fa fa-info-circle px-3"></i>Você está respondendo a pergunta de {question.dimensions[0].parent_dimension_name ? question.dimensions[0].parent_dimension_name : question.dimensions[0].dimension_name}
                              </p>
                            )}
                            {/* <span className="text-primary fs-36 fw-700 pr-2">
                              {index + 1}
                            </span>
                            /{" "}
                            <span className="gs-110 fs-20">
                              {survey.total_question_count}
                            </span> */}
                            {question.optional && (
                              <span className="pill bg-dark text-white ml-4">
                                Opcional
                              </span>
                            )}
                            {/* {saving && (
                              <span className="text-warning pl-4 fw-700 fs-18">
                                Salvando...
                              </span>
                            )}
                            {saved && (
                              <span className="text-success pl-4 fw-700 fs-18">
                                <i className="fa fa-check pr-2"></i>
                                Salvo
                              </span>
                            )}
                            {waitingSave && (
                              <span className="text-warning pl-4 fw-700 fs-18">
                                Avance para salvar
                              </span>
                            )} */}
                          </p>

                          {survey.kind === "performance" &&
                            survey.respondent_type === "leader" && (
                              <p className="bg-info p-3 br-8 text-white">
                                <i className="fa fa-info-circle px-3"></i>Você
                                está respondendo a pesquisa de avaliação de:{" "}
                                {survey.target_user_name}
                              </p>
                            )}
                          {question.is_immediate_leader &&
                          !survey.immediate_leader ? (
                            <div>
                              <p className="bg-warning p-3 br-8 text-white">
                                <i className="fa fa-info-circle px-3"></i>Esta
                                pergunta é sobre sua liderança imediata mas a
                                empresa não cadastrou nenhum líder imediato para
                                você. Por favor, pule essa pergunta.
                              </p>
                            </div>
                          ) : (
                            <div>
                              {question.is_immediate_leader && (
                                <p className="bg-info p-3 br-8 text-white">
                                  <i className="fa fa-info-circle px-3"></i>Seu
                                  líder imediato é:{" "}
                                  {survey.immediate_leader}
                                </p>
                              )}
                              {survey.kind !== "performance" ||
                              survey.respondent_type !== "leader" ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: question.prompt,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: question.alternative_prompt,
                                  }}
                                ></div>
                              )}
                              {survey.config_leader_can_see_user_answer_while_responding &&
                                survey.kind === "performance" &&
                                survey.respondent_type === "leader" && (
                                  <>
                                    {question.user_answer ? (
                                      <p className="bg-primary p-3 br-8 text-white">
                                        <i className="fa fa-info-circle px-3"></i>
                                        A resposta do(a) liderado(a) para essa
                                        pergunta foi: {question.user_answer}
                                      </p>
                                    ) : (
                                      <p className="bg-danger p-3 br-8 text-white">
                                        <i className="fa fa-info-circle px-3"></i>
                                        O(a) liderado(a) ainda não respondeu a essa
                                        pergunta.
                                      </p>
                                    )}
                                  </>
                                )}
                              {false &&
                                question.dimensions &&
                                question.dimensions.length > 0 && (
                                  <div className="flex align-items-center gap-12">
                                    {question.dimensions
                                      .filter((d) => d.file_url)
                                      .map((dimension) => (
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn-primary profiles-button mb-3"
                                            data-toggle="modal"
                                            data-target="#modal-pdf"
                                          >
                                            <p class="m-0">
                                              Ver arquivo de referência
                                            </p>
                                          </button>
                                          <div
                                            class="modal fade"
                                            id="modal-pdf"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                          >
                                            <div
                                              class="modal-dialog modal-lg modal-dialog-centered"
                                              role="document"
                                            >
                                              <div class="modal-content">
                                                <div class="modal-header">
                                                  <h5
                                                    class="modal-title"
                                                    id="exampleModalLabel"
                                                  >
                                                    Arquivo de referência da
                                                    dimensão {dimension.name}
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    class="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                  >
                                                    <span aria-hidden="true">
                                                      &times;
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="modal-body bg-light ">
                                                  <div
                                                    className="tab-content"
                                                    id="myTabContent"
                                                  >
                                                    <div
                                                      className="tab-pane fade show active"
                                                      id="apolice"
                                                      role="tabpanel"
                                                    >
                                                      <iframe
                                                        className="min-vh-100"
                                                        src={ensureSsl(
                                                          `${dimension.file_url}#view=fitH`
                                                        )}
                                                        height="100%"
                                                        width="100%"
                                                        title="Apolice"
                                                      >
                                                        {" "}
                                                      </iframe>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              {question.kind ===
                                "multiple_choice_allow_many" && (
                                <p className="bg-info p-3 br-8 text-white">
                                  <i className="fa fa-info-circle px-3"></i>
                                  Você pode selecionar várias opções
                                </p>
                              )}
                              {question.kind === "multiple_choice" && (
                                <div
                                  className={`flex w-100 ${
                                    mobilePage()
                                      ? "flex-column"
                                      : "align-items-center gap-32 flex-wrap"
                                  }`}
                                >
                                  {question.options.map((option) => {
                                    return (
                                      <p
                                        key={option.id}
                                        className={`form-option fs-16 ${
                                          question.answer
                                            .survey_cycle_question_option_id ===
                                            option.id && "selected"
                                        }`}
                                        onClick={(e) =>
                                          handleAnswerChange(
                                            question.id,
                                            option.text,
                                            option.value,
                                            option.id
                                          )
                                        }
                                      >
                                        <span className="pr-3 form-option-letter">
                                          {letterOrders[option.order]}
                                        </span>
                                        {option.text}
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                              {question.kind ===
                                "multiple_choice_allow_many" && (
                                <div
                                  className={`flex w-100 ${
                                    mobilePage()
                                      ? "flex-column"
                                      : "align-items-center gap-32 flex-wrap"
                                  }`}
                                >
                                  {question.options.map((option) => {
                                    return (
                                      <p
                                        key={option.id}
                                        className={`form-option fs-16 ${
                                          question.answer.survey_cycle_question_option_ids.includes(
                                            option.id
                                          ) && "selected"
                                        }`}
                                        onClick={(e) =>
                                          handleMultipleAnswerAllowManyOptionSelect(
                                            question.id,
                                            option.id
                                          )
                                        }
                                      >
                                        <span className="pr-3 form-option-letter">
                                          {letterOrders[option.order]}
                                        </span>
                                        {option.text}
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                              {question.kind === "open" && (
                                <div>
                                  <textarea
                                    value={question.answer.text}
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        question.id,
                                        e.target.value,
                                        e.target.value,
                                        null
                                      )
                                    }
                                    placeholder="Responda aqui"
                                    className="form-control mb-2"
                                    style={{ height: "30vh" }}
                                  ></textarea>
                                </div>
                              )}
                              {question.kind === "date_pick" && (
                                <div>
                                  <Datetime
                                    value={new Date(question.answer.text)}
                                    onChange={(date) =>
                                      handleAnswerChange(
                                        question.id,
                                        date,
                                        date,
                                        null
                                      )
                                    }
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    placeholder="Selecione uma data"
                                    className="datetime"
                                    inputProps={{
                                      placeholder: "Selecione uma data",
                                    }}
                                    style={{ height: "30vh" }}
                                  />
                                </div>
                              )}
                              {question.kind ===
                                "multiple_choice_with_last_option_open" && (
                                <div>
                                  <div
                                    className={`flex w-100 ${
                                      mobilePage()
                                        ? "flex-column"
                                        : "align-items-center gap-32 flex-wrap"
                                    }`}
                                  >
                                    {question.options
                                      .slice(0, -1)
                                      .map((option) => {
                                        return (
                                          <p
                                            key={option.id}
                                            className={`form-option fs-16 ${
                                              question.answer
                                                .survey_cycle_question_option_id ===
                                                option.id && "selected"
                                            }`}
                                            onClick={(e) =>
                                              handleAnswerChange(
                                                question.id,
                                                option.text,
                                                option.value,
                                                option.id
                                              )
                                            }
                                          >
                                            <span className="pr-3 form-option-letter">
                                              {letterOrders[option.order]}
                                            </span>
                                            {option.text}
                                          </p>
                                        );
                                      })}
                                  </div>
                                  <div className="flex align-items-center gap-48 w-100">
                                    <input
                                      value={question.answer.other_value}
                                      onChange={(e) =>
                                        handleAnswerChange(
                                          question.id,
                                          lastOption(question).text,
                                          lastOption(question).value,
                                          lastOption(question).id,
                                          e.target.value
                                        )
                                      }
                                      placeholder={lastOption(question).text}
                                      className="form-control mb-2 w-100"
                                    ></input>
                                  </div>
                                </div>
                              )}
                              {question.kind === "slider_10" && (
                                <div
                                  className={`my-5 ${
                                    mobilePage() ? "w-100" : "w-80"
                                  }`}
                                >
                                  <Slider
                                    trackStyle={[
                                      {
                                        backgroundColor: sliderColorForValue(
                                          question.answer.value,
                                          10
                                        ),
                                      },
                                    ]}
                                    handleStyle={[
                                      { backgroundColor: props.theme.colors.primary },
                                    ]}
                                    dotStyle={{
                                      backgroundColor: props.theme.colors.primary,
                                      borderColor: props.theme.colors.primary,
                                    }}
                                    step={1}
                                    min={1}
                                    max={10}
                                    marks={sliderMarks(10)}
                                    value={question.answer.value}
                                    onChange={(value) =>
                                      handleAnswerChange(
                                        question.id,
                                        value,
                                        value,
                                        null,
                                        null
                                      )
                                    }
                                  />
                                  {question.scale_description ? (
                                    <div
                                      className="mt-5 legend-description"
                                      dangerouslySetInnerHTML={{
                                        __html: question.scale_description,
                                      }}
                                    ></div>
                                  ) : (
                                    <div className="mt-5">
                                      <p className="mb-1">
                                        1-3: Comportamento abaixo do esperado
                                        para função.
                                      </p>
                                      <p className="mb-1">
                                        4-6: Comportamento observado na maioria
                                        das situações.{" "}
                                      </p>
                                      <p className="mb-1">
                                        7-8: Comportamento sempre observado{" "}
                                      </p>
                                      <p className="mb-1">
                                        9-10: Comportamento referência para
                                        empresa
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                              {question.kind === "slider_4" && (
                                <div
                                  className={`my-5 ${
                                    mobilePage() ? "w-100" : "w-80"
                                  }`}
                                >
                                  <Slider
                                    trackStyle={{ backgroundColor: props.theme.colors.primary }}
                                    handleStyle={[
                                      { backgroundColor: props.theme.colors.primary },
                                    ]}
                                    dotStyle={{
                                      backgroundColor: props.theme.colors.primary,
                                      borderColor: props.theme.colors.primary,
                                    }}
                                    step={1}
                                    min={1}
                                    max={4}
                                    marks={sliderMarks(4)}
                                    value={question.answer.value}
                                    onChange={(value) =>
                                      handleAnswerChange(
                                        question.id,
                                        value,
                                        value,
                                        null,
                                        null
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              {mobilePage() ? (
                <div>
                  <div className="flex align-items-center w-100 mt-3 gap-8">
                    <p className="btn btn-primary w-100" onClick={goToPrevious}>
                      <i className="fas fa-arrow-left pr-2"></i>
                      <br />
                      Voltar
                    </p>
                    <p
                      className={`btn btn-primary w-100 ${
                        !canGoToNext() && "disabled"
                      }`}
                      onClick={goToNext}
                    >
                      <i className="fas fa-arrow-right pr-2"></i>
                      <br />
                      Avançar
                    </p>
                  </div>
                  <p
                    className={`btn btn-primary w-100 ${
                      !canGoToNext() && "disabled"
                    }`}
                    onClick={goToNextUnanswered}
                  >
                    <i className="fas fa-forward pr-2"></i>
                    <br />
                    Próxima não respondida
                  </p>
                    { survey.onboarding !== "open" &&
                      <p
                        className="btn btn-success px-5 my-0 w-100"
                        onClick={saveAndExit}
                      >
                        <i className="fas fa-save pr-2"></i>
                        <br />
                        Salvar e sair
                      </p>
                    }
                </div>
              ) : (
                <div className="flex align-items-center w-100 mt-3">
                  <p
                    className="btn btn-primary px-5 my-0"
                    onClick={goToPrevious}
                  >
                    <i className="fas fa-arrow-left pr-2"></i>
                    Voltar
                  </p>
                  <p
                    className={`btn btn-primary px-5 my-0 ${
                      !canGoToNext() && "disabled"
                    }`}
                    onClick={goToNext}
                  >
                    <i className="fas fa-arrow-right pr-2"></i>
                    Avançar
                  </p>
                  <p
                    className={`btn btn-primary px-5 my-0 ${
                      !canGoToNext() && "disabled"
                    }`}
                    onClick={goToNextUnanswered}
                  >
                    <i className="fas fa-forward pr-2"></i>
                    Próxima não respondida
                  </p>
                  { survey.onboarding !== "open" &&
                    <p
                      className="btn btn-success px-5 my-0"
                      onClick={saveAndExit}
                    >
                      <i className="fas fa-save pr-2"></i>
                      Salvar e sair
                    </p>
                  }
                </div>
              )}
              <div className="flex align-items-center w-100 mt-3 flex-wrap"></div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
