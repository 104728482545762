import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import Datetime from "react-datetime";
import Modal from "react-modal";
import ChartDataLabels from "chartjs-plugin-datalabels";
import GaugeChart from "react-gauge-chart";
import { HorizontalStackedBars } from "../components/HorizontalStackedBars";
import Loader from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const customModalStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-40%, -50%)",
    transition: "2s",
    width: "40%",
    height: "50vh",
    zIndex: 999,
  },
};

const respondentTypeOptions = [
  { label: "Todos", value: "all" },
  { label: "Líderes", value: "leader" },
  { label: "Autoavaliação", value: "user" },
];

export function SurveyCycleResults(props) {
  const [survey, setSurvey] = useState();
  const [questions, setQuestions] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [profileFields, setProfileFields] = useState([]);
  const [availableSurveyCycles, setAvailableSurveyCycles] = useState([]);
  const [currentSurveyCycleId, setCurrentSurveyCycleId] = useState(
    Number(props.id)
  );
  const [evolutionMode, setEvolutionMode] = useState(false);
  const [initialRequestLoading, setInitialRequestLoading] = useState(true);

  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [currentDimensionId, setCurrentDimensionId] = useState();

  const [displayTitle, setDisplayTitle] = useState("");
  const [displaySubtitle, setDisplaySubtitle] = useState("");
  const [displaySubSubtitle, setDisplaySubSubtitle] = useState("");

  const [selectedProfileField, setSelectedProfileField] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [summarizeByProfileFieldId, setSummarizeByProfileFieldId] = useState();
  const [summarizeByDimensions, setSummarizeByDimensions] = useState(false);
  const [summarizeByQuestions, setSummarizeByQuestions] = useState(false);
  const [summarizeByCollaborator, setSummarizeByCollaborator] = useState(false);
  const [respondentType, setRespondentType] = useState("all");

  const [highlight, setHighlight] = useState();
  const [barChartData, setBarChartData] = useState();
  const [gaugeChartValue, setGaugeChartValue] = useState(0);
  const [gaugeChartFormat, setGaugeChartFormat] = useState();
  const [dimensionMainValue, setDimensionMainValue] = useState();
  const [dimensionSecondaryValue, setDimensionSecondaryValue] = useState();
  const [dimensionTertiaryValue, setDimensionTertiaryValue] = useState();
  const [stackedHorizontalData, setStackedHorizontalData] = useState([]);
  const [stackedHorizontalLabels, setStackedHorizontalLabels] = useState([]);
  const [blockVisualization, setBlockVisualization] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [aiAnalysis, setAiAnalysis] = useState();
  const [dimensionQuestionsContent, setDimensionQuestionsContent] = useState([]);

  const [openAnswersData, setOpenAnswersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState({});
  const [surveyCycleQuestions, setSurveyCycleQuestions] = useState(null);


  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [showRespondentName, setShowRespondentName] = useState(false);

  const [userIsAdmin, setUserIsAdmin] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const [displayingUserForms, setDisplayingUserForms] = useState(false);
  const [safetyVisualizationProfiles, setSafetyVisualizationProfiles] = useState(null);


  const pageSize = 50;
  const questionsRef = useRef();
  const dimensionsRef = useRef();
  const availableSurveyCyclesRef = useRef();

  const barChartOptions = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 3,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: props.theme.colors.primary,
        font: {
          size: "20px",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'black', // Set the color for labels below the bars
        },
      },
      y: {
        ticks: {
          color: 'black', // Set the color for labels below the bars
        },
      },
    },
    backgroundColor: props.theme.colors.primary,
  };

  useEffect(() => {
    fetchBasicData();
    registerEventListeners();
    checkUserRole();
  }, []);

  const registerEventListeners = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowRight") {
        changeQuestionOrDimension(+1);
      } else if (e.key === "ArrowLeft") {
        changeQuestionOrDimension(-1);
      } else if (e.key === "p") {
        changeSurveyCycle(+1);
      } else if (e.key === "o") {
        changeSurveyCycle(-1);
      }
    });
  }

  const changeQuestionOrDimension = (amount) => {
    const currentQuestionIdState = document.querySelector("#main").dataset.currentQuestionId
    const currentDimensionIdState = document.querySelector("#main").dataset.currentDimensionId

    if (currentQuestionIdState) {
      const array = questionsRef.current
      const index = array.findIndex((el) => el.question_id === Number(currentQuestionIdState));
      if (index + amount >= 0 && index + amount < array.length ) setCurrentQuestionId(array[index + amount].question_id);
    }
    if (currentDimensionIdState) {
      const array = dimensionsRef.current
      const index = array.findIndex((el) => el.id === Number(currentDimensionIdState));
      if (index + amount >= 0 && index + amount < array.length ) setCurrentDimensionId(array[index + amount].id);
    }
  }
  const changeSurveyCycle = (amount) => {
    const currentSurveyCycleIdState =
      document.querySelector("#main").dataset.currentSurveyCycleId;

      if (currentSurveyCycleIdState) {
        const array = availableSurveyCyclesRef.current;
        console.log(array);
      const index = array.findIndex(
        (el) => el.id === Number(currentSurveyCycleIdState)
      );
      if (index + amount >= 0 && index + amount < array.length)
        setCurrentSurveyCycleId(array[index + amount].id);
    }
  };

  const fetchBasicData = async () => {
    const response = await axios.get(
      `/api/v1/survey_cycles/${currentSurveyCycleId}`
    );
    const dimensions = [
      ...response.data.dimensions,
      { id: "global", name: "Global - Todas as dimensões" },
    ];

    setInitialRequestLoading(false);
    setSurvey(response.data.survey);
    setQuestions(response.data.survey_cycle_questions);
    setDimensions(dimensions);
    setProfileFields(response.data.profile_fields);
    setAvailableSurveyCycles(response.data.available_survey_cycles);
    setSafetyVisualizationProfiles(response.data.safety_visualization_profiles);
    questionsRef.current = response.data.survey_cycle_questions;
    dimensionsRef.current = dimensions;
    availableSurveyCyclesRef.current = response.data.available_survey_cycles;
  };


  const checkUserRole = async () => {
    const response = await axios.get(
      `/users/is_admin`
    );
    setUserIsAdmin(response.data.is_admin)
  };


  useEffect(async () => {
    setErrorMessage("");
    fetchQuestionResults();
    fetchDimensionResults();
    fetchDimensionByProfileField();
    fetchGlobalDimensionResult();
    fetchGlobalQuestionsResult();
    fetchQuestionByProfileField();
    fetchQuestionEvolution();
    fetchDimensionEvolution();
    fetchDimensionByCollaborator();
  }, [
    currentQuestionId,
    currentDimensionId,
    currentSurveyCycleId,
    selectedFilters,
    summarizeByProfileFieldId,
    summarizeByDimensions,
    summarizeByQuestions,
    summarizeByCollaborator,
    evolutionMode,
    respondentType,
  ]);

  const fetchQuestionResults = async () => {
    if (
      !currentQuestionId ||
      summarizeByDimensions ||
      summarizeByProfileFieldId ||
      evolutionMode
    ) {
      setOpenAnswersData([]);
      setBarChartData(null);
      return;
    }

    setLoading(true);
    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/question_answers?question_id=${currentQuestionId}`;
    if (selectedFilters.length) {
      url = url + `&profile_field_id=${selectedFilters[0].fieldId}`;
      selectedFilters.forEach((filter) => {
        url = url + `&profile_field_value=${filter.fieldValue}`;
      });
    }
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);

    const multipleChoiceAnswerData = response.data.bar_data;
    setDisplaySubSubtitle(response.data.display_sub_subtitle);

    if (multipleChoiceAnswerData.length) {
      setBarChartData({
        labels: multipleChoiceAnswerData.map((data) => data.text),
        datasets: [
          {
            label: "Respostas",
            data: multipleChoiceAnswerData.map((data) => data.percentage),
            borderColor: props.theme.colors.primary,
            backgroundColor: props.theme.colors.primaryLight,
          },
        ],
      });
      setHighlight(response.data.highlight);
    } else {
      setBarChartData();
    }

    setLoading(false);
    setOpenAnswersData(response.data.open_answers);
    setBlockVisualization(response.data.block_visualization);
    if (response.data.open_answers && response.data.open_answers.length > 0) {
      analyzeOpenAnswersWithAi();
    }
  };

  const analyzeOpenAnswersWithAi = async () => {
    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/open_question_ai_analysis?question_id=${currentQuestionId}`;
    if (selectedFilters.length) {
      url = url + `&profile_field_id=${selectedFilters[0].fieldId}`;
      selectedFilters.forEach((filter) => {
        url = url + `&profile_field_value=${filter.fieldValue}`;
      });
    }
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setAiLoading(true);
    try {
      const response = await axios.get(url);
      setAiAnalysis(response.data.analysis);
      setAiLoading(false);
    } catch {
      setAiAnalysis("Houve algum erro ao gerar a analise de AI.");
    } finally {
      setAiLoading(false);
    }
  }

  const fetchDimensionResults = async () => {
    if (
      !currentDimensionId ||
      summarizeByProfileFieldId ||
      summarizeByDimensions ||
      summarizeByCollaborator ||
      evolutionMode
    ) {
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/dimension_favorability?dimension_id=${currentDimensionId}`;
    if (selectedFilters.length) {
      url = url + `&profile_field_id=${selectedFilters[0].fieldId}`;
      selectedFilters.forEach((filter) => {
        url = url + `&profile_field_value=${filter.fieldValue}`;
      });
    }
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);
    setGaugeChartValue(response.data.main_value);
    setDimensionMainValue(response.data.main_value);
    setDimensionSecondaryValue(response.data.secondary_value);
    setDimensionTertiaryValue(response.data.tertiary_value);
    setGaugeChartFormat(response.data.format);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
    setDimensionQuestionsContent(response.data.aggregatable_questions)
  };

  const fetchGlobalDimensionResult = async () => {
    if (!summarizeByDimensions) {
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/global_dimensions_summarize?`;
    if (selectedFilters.length) {
      url = url + `&profile_field_id=${selectedFilters[0].fieldId}`;
      selectedFilters.forEach((filter) => {
        url = url + `&profile_field_value=${filter.fieldValue}`;
      });
    }
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);
    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchGlobalQuestionsResult = async () => {
    if (!summarizeByQuestions) {
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/global_questions_summarize?`;
    if (selectedFilters.length) {
      url = url + `&profile_field_id=${selectedFilters[0].fieldId}`;
      selectedFilters.forEach((filter) => {
        url = url + `&profile_field_value=${filter.fieldValue}`;
      });
    }
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchQuestionByProfileField = async () => {
    if (((!summarizeByProfileFieldId) || !currentQuestionId)) {
      setStackedHorizontalData([]);
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/question_profile_summarize?question_id=${currentQuestionId}&profile_field_id=${summarizeByProfileFieldId}`;
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);
    setBarChartData(null);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchDimensionByProfileField = async () => {
    if ((!summarizeByProfileFieldId || !currentDimensionId)) {
      setStackedHorizontalData([]);
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/dimension_profile_summarize?dimension_id=${currentDimensionId}&profile_field_id=${summarizeByProfileFieldId}`;
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);

    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchDimensionByCollaborator = async () => {
    if (!summarizeByCollaborator || !currentDimensionId) {
      setStackedHorizontalData([]);
      return;
    }

    let url = `/api/v1/survey_cycles/${currentSurveyCycleId}/dimension_collaborator_summarize?dimension_id=${currentDimensionId}`;
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);

    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchQuestionEvolution = async () => {
    if (!evolutionMode || !currentQuestionId) {
      setStackedHorizontalData([]);
      return;
    }

    let url = `/api/v1/survey_cycles/${props.id}/question_evolution?question_id=${currentQuestionId}`;
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);

    setBarChartData(null);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };

  const fetchDimensionEvolution = async () => {
    if (!evolutionMode || !currentDimensionId) {
      setStackedHorizontalData([]);
      return;
    }

    let url = `/api/v1/survey_cycles/${props.id}/dimension_evolution?dimension_id=${currentDimensionId}`;
    if (respondentType !== "all")
      url = url + `&respondent_type=${respondentType}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;

    setLoading(true);
    const response = await axios.get(url);

    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);

    setGaugeChartValue(null);
    setBarChartData(null);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setLoading(false);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
  };


  const fetchProfileFieldDimensionsSummarize = async (profileFieldId, profileFieldValue) => {
    if (!profileFieldId || !profileFieldValue) {
      console.error('Invalid parameters provided.');
      return;
    }
    let url = `/api/v1/survey_cycles/${props.id}/profile_field_dimensions_summarize?profile_field_id=${profileFieldId}&profile_field_value=${profileFieldValue}`;
    url = url + `&authentication_hash=${props.authenticationHash}`;
    setLoading(true);
    const response = await axios.get(url);


    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }

    setDisplayTitle(response.data.display_title);
    setDisplaySubtitle(response.data.display_subtitle);
    setDisplaySubSubtitle(response.data.display_sub_subtitle);
    setStackedHorizontalData(response.data.chart_data);
    setStackedHorizontalLabels(response.data.chart_labels);
    setBlockVisualization(response.data.block_visualization);
    setErrorMessage(response.data.error_message);
    setHighlight(response.data.highlight);
    setLoading(false);
  };

  const groupByProfileField = (profileField, e) => {
    e.stopPropagation();
    exitEvolutionMode();
    setEvolutionMode(false);
    setSelectedFilters([]);
    setSelectedProfileField(null);
    setGaugeChartValue(null);
    setSummarizeByDimensions(false);
    setSummarizeByQuestions(false);
    setSummarizeByCollaborator(false);
    setSummarizeByProfileFieldId(
      summarizeByProfileFieldId !== profileField.id ? profileField.id : null
    );
  };

  const dimensionCollaboratorSummarize = () => {
    setSelectedFilters([]);
    setSelectedProfileField(null);
    setBarChartData(null);
    setGaugeChartValue(null);
    setSummarizeByCollaborator(!summarizeByCollaborator);
    setSummarizeByDimensions(false);
    setSummarizeByQuestions(false);
    setCurrentQuestionId(null);
    setSummarizeByProfileFieldId(null);
    setHighlight(null);
    setEvolutionMode(false);
  };

  const globalDimensionsSummarize = () => {
    setSelectedFilters([]);
    setSelectedProfileField(null);
    setBarChartData(null);
    setGaugeChartValue(null);
    setSummarizeByDimensions(!summarizeByDimensions);
    setSummarizeByQuestions(false);
    setSummarizeByCollaborator(false);
    setCurrentQuestionId(null);
    setCurrentDimensionId(null);
    setSummarizeByProfileFieldId(null);
    setHighlight(null);
    setEvolutionMode(false);
  };

  const globalQuestionsSummarize = () => {
    setSelectedFilters([]);
    setSelectedProfileField(null);
    setBarChartData(null);
    setGaugeChartValue(null);
    setSummarizeByQuestions(!summarizeByQuestions);
    setSummarizeByDimensions(false);
    setCurrentQuestionId(null);
    setCurrentDimensionId(null);
    setSummarizeByProfileFieldId(null);
    setHighlight(null);
    setEvolutionMode(false);
  };

  const setFilter = (
    profileFieldId,
    profileFieldName,
    profileFieldValue,
    profileFieldLabel
  ) => {
    let currentSelectedFilters = [...selectedFilters];

    const filterObject = {
      fieldId: profileFieldId,
      fieldName: profileFieldName,
      fieldValue: profileFieldValue,
      fieldLabel: profileFieldLabel,
      uniqueKey: `${profileFieldName} - ${profileFieldValue}`,
    };

    const elementIndex = currentSelectedFilters
      .map((selectedFilter) => selectedFilter.uniqueKey)
      .indexOf(filterObject.uniqueKey);
    if (elementIndex === -1) {
      currentSelectedFilters = [filterObject];
    } else {
      currentSelectedFilters = [];
    }

    setSummarizeByProfileFieldId(null);
    setSelectedFilters(currentSelectedFilters);
  };

  const changeSelectedProfileField = (profileField) => {
    setSelectedFilters([]);
    setSelectedProfileField(
      selectedProfileField !== profileField ? profileField : null
    );
  };

  const handleQuestionChange = (questionId) => {
    setCurrentQuestionId(questionId);
    setGaugeChartValue(null);
    setCurrentDimensionId(null);
    setSummarizeByDimensions(false);
    setSummarizeByQuestions(false);
    setSummarizeByCollaborator(false);
    setHighlight(null);
  };
  const handleDimensionChange = (dimensionId) => {
    setCurrentDimensionId(dimensionId);
    setBarChartData(null);
    setCurrentQuestionId(null);
    setSummarizeByDimensions(false);
    setSummarizeByQuestions(false);
    setHighlight(null);
  };

  const questionList = () => {
    return questions.map((question) => {
      return {
        value: question.question_id,
        label: `${question.order} - ${question.title}`,
      };
    });
  };


  const dimensionList = () => {
    return dimensions.map((dimension) => {
      const parentName = dimension.parent_dimension ? dimension.parent_dimension : '';
      const label = parentName ? `${dimension.name} (${parentName})` : dimension.name;

      return {
        value: dimension.id,
        label: label,
      };
    });
  };

  const parseFloatToPercent = (float) => {
    return (parseFloat(float) * 100).toFixed(1) + "%";
  };

  function parseFloatSafe(n) {
    return parseFloat((n * 10).toString().slice(0, 4));
  }

  const handleSetCurrentSurveyCycleId = (surveyCycleId) => {
    setEvolutionMode(false);
    setCurrentSurveyCycleId(surveyCycleId);
  };

  const handleSetEvolutionMode = () => {
    setEvolutionMode(true);
    setCurrentSurveyCycleId(null);
  };

  const exitEvolutionMode = () => {
    if (!evolutionMode) return;
    setEvolutionMode(false);
    setCurrentSurveyCycleId(Number(props.id));
  };

  const handleShowUserSurveyCyclesAllAnswers = async (pageNumber) => {
    setLoading(true)
    const response = await axios.get(`/api/v1/survey_cycles/${props.id}/questions_and_users_answers`, {
      params: {
        authentication_hash: props.authenticationHash,
        page: pageNumber,
        pageSize: pageSize
      }
    });
    if (response.data.error_message) {
      setLoading(false);
      setErrorMessage(response.data.error_message);
      return;
    }
    setTotalPages(response.data.total_pages)
    setDisplayingUserForms(true)
    setQuestionAnswers(response.data.question_answers);
    setSurveyCycleQuestions(response.data.survey_cycle_questions)
    setLoading(false);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => {
        const updatedPage = prevPage - 1;
        handleShowUserSurveyCyclesAllAnswers(updatedPage);
        return updatedPage;
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => {
        const updatedPage = prevPage + 1;
        handleShowUserSurveyCyclesAllAnswers(updatedPage);
        return updatedPage;
      });
    }
  };

  const handlePreviousUser = () => {
    setCurrentUserIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNextUser = () => {
    setCurrentUserIndex(prevIndex => Math.min(prevIndex + 1, Object.keys(questionAnswers).length - 1));
  };

  const currentUserKey = Object.keys(questionAnswers)[currentUserIndex];

  const toggleRespondentName = () => {
    setShowRespondentName((prevValue) => !prevValue);
  };

  const navigateToUser = (userKey) => {
    const index = Object.keys(questionAnswers).indexOf(userKey);
    setCurrentUserIndex(index);
  };

  const handleHideUserSurveyCyclesAllAnswers = () => {
    setTotalPages(null)
    setDisplayingUserForms(false)
    setQuestionAnswers({});
    setSurveyCycleQuestions(null)
  };



  return (
    <div
      id="main"
      data-current-question-id={currentQuestionId}
      data-current-dimension-id={currentDimensionId}
      data-current-survey-cycle-id={currentSurveyCycleId}
    >
      {!initialRequestLoading ? (
        <div className="flex align-items-center justify-content-start my-3 gap-24">
          <Select
            options={questionList()}
            placeholder="Questão"
            onChange={(e) => handleQuestionChange(e.value)}
            value={questionList().filter(
              (opt) => opt.value === currentQuestionId
            )}
            className="w-100 mb-2"
          />

          <Select
            options={dimensionList()}
            placeholder="Dimensão"
            onChange={(e) => handleDimensionChange(e.value)}
            value={dimensionList().filter(
              (opt) => opt.value === currentDimensionId
            )}
            className="w-100 mb-2"
          />
        </div>
      ) : (
        <div className="flex align-items-center justify-content-start my-3 gap-24">
          <Skeleton height={40} width={700} />
          <Skeleton height={40} width={700} />
        </div>
      )}
      <div className="flex align-items-start justify-content-around gap-48">
        <div className="w-20">
          <h3>Filtros e agregadores</h3>

          {initialRequestLoading && <Skeleton count={5} height={70} />}

          {profileFields.map((profileField) => {
            return (
              <div>
                <div
                  className="clickable hoverable p-2 br-8 bg-gs-230 flex align-items-center justify-content-between mb-2"
                  onClick={() => changeSelectedProfileField(profileField)}
                >
                  <p className="m-0">
                    <i
                      className={`fa px-3 ${
                        profileField === selectedProfileField
                          ? "fa-caret-up"
                          : "fa-caret-down"
                      }`}
                    ></i>
                    {profileField.name}
                  </p>
                  <span
                    className={`px-3 py-1 hoverable clickable ${
                      profileField.id === summarizeByProfileFieldId
                        ? "bg-primary text-white"
                        : "bg-white"
                    }`}
                    onClick={(e) => groupByProfileField(profileField, e)}
                  >
                    G
                  </span>
                </div>
                {profileField === selectedProfileField &&
                  profileField.values.filter(profileFieldValue => profileFieldValue.completed_count > safetyVisualizationProfiles).map((profileFieldValue) => {
                    return (
                      <p
                        className={`clickable hoverable p-2 br-8 ${
                          !profileFieldValue.name && "text-danger"
                        } ${
                          selectedFilters
                            .map((filter) => filter.uniqueKey)
                            .includes(
                              `${selectedProfileField.name} - ${profileFieldValue.name}`
                            ) && "bg-primary text-white"
                        }`}
                        onClick={() => {
                          setFilter(
                            selectedProfileField.id,
                            selectedProfileField.name,
                            profileFieldValue.name,
                            selectedProfileField.name
                          );
                          if (selectedProfileField.id && !currentQuestionId && !currentDimensionId) {
                            fetchProfileFieldDimensionsSummarize(selectedProfileField.id, profileFieldValue.name);
                          }
                        }}
                      >
                        {profileFieldValue.name || "Sem valor"} ({profileFieldValue.completed_count}/
                        {profileFieldValue.count}

                          {profileFieldValue.count > 0 &&
                            ` | ${((profileFieldValue.completed_count / profileFieldValue.count) * 100).toFixed(2)}%`
                          }
                        )
                      </p>
                    );
                  })
                }
              </div>
            );
          })}

          {survey && survey.kind === "performance" && (
            <div className="clickable hoverable p-2 br-8 bg-gs-230 flex align-items-center justify-content-between mb-2 mt-4">
              <p className="m-0">Por colaborador</p>
              <span
                className={`px-3 py-1 hoverable clickable ${
                  summarizeByCollaborator ? "bg-primary text-white" : "bg-white"
                }`}
                onClick={dimensionCollaboratorSummarize}
              >
                G
              </span>
            </div>
          )}

          <div className="clickable hoverable p-2 br-8 bg-gs-230 flex align-items-center justify-content-between mb-2 mt-4">
            <p className="m-0">Dimensões</p>
            <span
              className={`px-3 py-1 hoverable clickable ${
                summarizeByDimensions ? "bg-primary text-white" : "bg-white"
              }`}
              onClick={globalDimensionsSummarize}
            >
              G
            </span>
          </div>
          <div className="clickable hoverable p-2 br-8 bg-gs-230 flex align-items-center justify-content-between mb-2">
            <p className="m-0">Questões</p>
            <span
              className={`px-3 py-1 hoverable clickable ${
                summarizeByQuestions ? "bg-primary text-white" : "bg-white"
              }`}
              onClick={globalQuestionsSummarize}
            >
              G
            </span>
          </div>

          <div className="spacer-40"></div>

          {survey && survey.kind === "performance" && (
            <div className="">
              <h3>Respondente</h3>
              {respondentTypeOptions.map((respondentTypeOption) => {
                return (
                  <p
                    className={`w-100 p-2 br-8 flex align-items-center justify-content-between mb-20 btn ${
                      respondentType === respondentTypeOption.value
                        ? "btn-primary"
                        : "btn-secondary"
                    }`}
                    onClick={() =>
                      setRespondentType(respondentTypeOption.value)
                    }
                  >
                    {respondentTypeOption.label}
                  </p>
                );
              })}
            </div>
          )}
          {survey && survey.kind === "climate" && userIsAdmin && (
            !displayingUserForms ? (
              <p
                className={`w-100 p-2 br-8 flex align-items-center justify-content-between mb-20 btn ${
                  evolutionMode ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => handleShowUserSurveyCyclesAllAnswers(currentPage)}
              >
                Formulários individuais
              </p>
            ) : (
              <p
                className={`w-100 p-2 br-8 flex align-items-center justify-content-between mb-20 btn ${
                  evolutionMode ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => handleHideUserSurveyCyclesAllAnswers()}
              >
                Esconder formulários
              </p>
            )
          )}


          <div className="spacer-40"></div>

          {availableSurveyCycles.length > 1 && (
            <div className="">
              <h3>Ciclos de pesquisa</h3>
              {availableSurveyCycles.map((cycle) => {
                return (
                  <p
                    className={`w-100 p-2 br-8 flex align-items-center justify-content-between mb-20 btn ${
                      currentSurveyCycleId === cycle.id
                        ? "btn-primary"
                        : "btn-secondary"
                    }`}
                    onClick={() => handleSetCurrentSurveyCycleId(cycle.id)}
                  >
                    {cycle.reference_name}
                  </p>
                );
              })}
              <div className="spacer-30"></div>

              <p
                className={`w-100 p-2 br-8 flex align-items-center justify-content-between mb-20 btn ${
                  evolutionMode ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => handleSetEvolutionMode()}
              >
                Modo evolução
              </p>
              <span className="fs-12">
                O modo evolução pode ser utilizado para comparar os resultados
                entre ciclos de pesquisa para questões e dimensões simples
              </span>
            </div>
          )}
        </div>

        <div className="f-1x br-8 p-3">
          <h2
            className="gs-50 fs-20"
            dangerouslySetInnerHTML={{
              __html: displayTitle,
            }}
          ></h2>
          <p
            className="gs-110 fs-16 text-primary"
            dangerouslySetInnerHTML={{
              __html: displaySubtitle,
            }}
          ></p>
          <p className="gs-110 fs-13 text-primary">{displaySubSubtitle}</p>
          {selectedFilters.length > 0 && false && (
            <p className="my-3 info-text br-8">
              <i className="fa fa-info-circle px-3"></i>
              Filtrado por: {selectedFilters[0].fieldLabel} ={" "}
              {selectedFilters.map((filter) => filter.fieldValue).join(", ")}
            </p>
          )}

          {!blockVisualization && !errorMessage ? (
            <div>
              <div className="results-div position-relative">
                {loading && (
                  <div className="loading-div flex align-items-center justify-content-around vh-50">
                    <Loader
                      type="Grid"
                      color={props.theme.colors.primary}
                      height={200}
                      width={200}
                    />
                  </div>
                )}
                {questionAnswers && Object.keys(questionAnswers).length > 0 && !loading && (
                  <div>
                    <div className="d-flex justify-content-between my-3">
                      <button className="btn btn-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
                      <span>{currentPage}/{totalPages}</span>
                      <button className="btn btn-primary" onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
                    </div>
                    <div className="d-flex justify-content-between mb-5 p-3 bg-secondary rounded">
                      <button onClick={handlePreviousUser} disabled={currentUserIndex === 0} className="btn btn-primary">
                        <i className="fas fa-backward"></i>
                      </button>
                      <p className="fw-600 fs-22">
                        {currentUserIndex + 1}/{Object.keys(questionAnswers).length}
                      </p>
                      <button onClick={handleNextUser} disabled={currentUserIndex === Object.keys(questionAnswers).length - 1} className="btn btn-primary">
                        <i className="fas fa-forward"></i>
                      </button>
                    </div>

                    {userIsAdmin && (<>
                      <div>
                        <input
                          type="checkbox"
                          id="toggleRespondentName"
                          checked={showRespondentName}
                          onChange={toggleRespondentName}
                        />
                        <label htmlFor="toggleRespondentName">Mostrar respondente</label>
                      </div>
                      {showRespondentName && (
                        <h3 className="p-3 bg-primary text-white rounded my-2">
                          {currentUserKey} ({questionAnswers[currentUserKey].length}/{surveyCycleQuestions})
                        </h3>
                      )}
                      <div className="w-100">
                        <select className="form-control" value={selectedUser} onChange={(e) => navigateToUser(e.target.value)}>
                          <option value="">Filtrar usuário da página</option>
                          {Object.keys(questionAnswers).map((userKey, index) => (
                            <option key={index} value={userKey}>
                              {userKey}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>)}

                    {questionAnswers[currentUserKey].length === 0 ? (
                      <div className="p-3 bg-secondary my-3">
                        <h4>Pesquisa Não respondida.</h4>
                      </div>
                    ) : (
                      questionAnswers[currentUserKey].map((answer, qIndex) => (
                        <div key={qIndex} className="question-answer p-3 bg-secondary my-3 rounded">
                          <h4>{answer.question}</h4>
                          <p>{answer.answer}</p>
                        </div>
                      ))
                    )}

                    <div className="d-flex justify-content-between">
                      <button className="btn btn-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>Anterior</button>
                      <span>{currentPage}/{totalPages}</span>
                      <button className="btn btn-primary" onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
                    </div>
                  </div>
                )}

                {initialRequestLoading && (
                  <div className="vh-50 d-flex align-items-center justify-content-around">
                    <p className="text-primary fw-700 fs-20">
                      Carregando informações da pesquisa...
                    </p>
                  </div>
                )}

                {highlight && (
                  <div className="flex justify-content-around w-100 text-primary">
                    <p className="fw-700 fs-22">{highlight}</p>
                  </div>
                )}

                {barChartData && (
                  <div className="vh-50">
                    <Bar options={barChartOptions} data={barChartData} />
                  </div>
                )}
                <div
                  className={`vh-100 flex align-items-center justify-content-around ${
                    !gaugeChartValue && "hide"
                  }`}
                >
                  <div>
                    <div className="h-50">
                      <GaugeChart
                        style={{ width: 1000 }}
                        id="gauge-chart6"
                        nrOfLevels={15}
                        colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                        textColor={"black"}
                        arcWidth={0.25}
                        percent={
                          gaugeChartFormat === "%"
                            ? gaugeChartValue
                            : parseFloatSafe(gaugeChartValue)
                        }
                        needleColor={props.theme.colors.primary}
                        needleBaseColor={props.theme.colors.primary}
                        animate={false}
                        formatTextValue={(val) =>
                          `${
                            gaugeChartFormat === "%"
                              ? val
                              : parseFloatSafe(gaugeChartValue * 10)
                          }${gaugeChartFormat}`
                        }
                      />
                    </div>
                    {gaugeChartFormat == "%" && (
                      <div className="my-2 flex align-items-center justify-content-between w-80">
                        <p>
                          <span className="fw-700">Favorável: </span>
                          {parseFloatToPercent(dimensionMainValue)}
                        </p>
                        <p>
                          <span className="fw-700">Neutro: </span>
                          {parseFloatToPercent(dimensionSecondaryValue)}
                        </p>
                        <p>
                          <span className="fw-700">Desfavorável: </span>
                          {parseFloatToPercent(dimensionTertiaryValue)}
                        </p>
                      </div>
                    )}
                    {dimensionQuestionsContent && dimensionQuestionsContent.length > 0 && dimensionQuestionsContent.length < 15 && currentDimensionId !== "global" &&(<>
                      <h3>Perguntas:</h3>
                      <ul>
                        {dimensionQuestionsContent.map((question, index) => (
                          <li key={index} className="fs-14">{question.prompt}</li>
                        ))}
                      </ul>
                    </>)}
                  </div>
                </div>
                {stackedHorizontalData.length > 0 && (
                  <div className="vh-100">
                    <HorizontalStackedBars
                      data={stackedHorizontalData}
                      labels={stackedHorizontalLabels}
                    />
                  </div>
                )}
                {openAnswersData.length > 0 && (
                  <div>
                    <div className="bg-primary p-4 br-8 my-4">
                      {aiLoading ? (
                        <div className="d-flex align-items-center justify-content-between flex-column gap-24">
                          <div className="my-4">
                            <Loader
                              type="Grid"
                              color="white"
                              height={200}
                              width={200}
                            />
                          </div>
                          <p className="text-white">
                            A análise de inteligência artificial está
                            carregando...
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p className="text-white fw-700">
                            Análise da Inteligência Artificial
                          </p>
                          <p className="text-white">
                            O resumo das respostas para a pergunta é:
                          </p>
                          <p className="text-white">{aiAnalysis}</p>
                          <p className="text-white">
                            <em>
                              É importante lembrar que a análise de inteligência
                              artificial apresenta apenas um resumo
                              auxiliar; ela pode cometer erros ou dar
                              importância maior ou menor do que deveria para
                              determinadas informações.
                            </em>
                          </p>
                        </div>
                      )}
                    </div>

                    <h2>Respostas abertas ({openAnswersData.length})</h2>
                    {openAnswersData.map((answerData) => {
                      return (
                        <p className="bg-gs-230 p-4 br-8">
                          {answerData.content}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {errorMessage && (
                <div>
                  <p className="danger-text p-5 m-0">{errorMessage}</p>
                </div>
              )}
              {blockVisualization && (
                <div>
                  <p className="danger-text p-5 m-0">
                    <i className="fas fa-eye-slash"></i> Essa visualização está
                    bloqueada porque você selecionou filtros com 3 ou menos
                    respostas de colaboradores. Esse bloqueio acontece para
                    preservar a segurança e anonimato dos respondentes.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
